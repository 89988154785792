
import {Component} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "../../misc/ErrorMessageHandler.mixins";
import {namespace} from "vuex-class";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import Company from "@/models/Company";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import MerchantRepository from "@/api/repositories/MerchantRepository";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    employeeCopy: {
      firstName: {required, maxLength: maxLength(MaxLengthValidation.FIRST_NAME)},
      lastName: {required, maxLength: maxLength(MaxLengthValidation.LAST_NAME)},
      account: {
        email: {required, email, maxLength: maxLength(MaxLengthValidation.EMAIL)},
      }
    }
  }
})

export default class StripeConnectActivateComponent extends mixins(ErrorMessageHandlerMixin) {
  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany!: Company;

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading: boolean = false;

  /**
   * updates the information of the company
   * @private
   */
  private async onActivateStripeConnect() {
    await this.tryStripeConnect();
  }

  /**
   * return url that is used when the stripe onBoarding is finished
   * @private
   */
  private get returnUrl() {
    return process.env.VUE_APP_STRIPE_RETURN_URL! + this.currentCompany?.id + '?scc=true';
  }

  /**
   * tries to get the set up url of stripe, opens new tab with the url to start connecting the user with stripe
   * @private
   */
  private async tryStripeConnect() {
    try {
      this.isLoading = true;

      // gets stripe connect link
      const response = await MerchantRepository.postSetup(this.currentCompany.id, this.returnUrl);

      // creates virtual link that opens stripe connect link in new tab, removes virtual afterwards
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = response.data['onboardingUrl'];
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.WRONG_ADDRESS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.CONNECT.ERROR');
            break;
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.$emit('closeDialog', deleted);
  }

}

